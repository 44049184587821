import React from 'react'
import loadable from '@loadable/component'
import { Styled } from 'theme-ui'
import PrismCore from './prism'

const ThemeUIPrism = loadable(() => import('@theme-ui/prism'))

const CodeBlock = props => (
  // eslint-disable-next-line react/jsx-pascal-case
  <ThemeUIPrism {...props} fallback={<Styled.pre>{props.children}</Styled.pre>} Prism={PrismCore}/>
)

export default CodeBlock
